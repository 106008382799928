let $ = require('jquery');
window.$ = window.jQuery = $;
require('./smartCombos.js');
require('ie-class');
require('hammerjs');
require('popper.js');
require('bootstrap');
/* If needed, add other dependencies here.

   Remember:
   - Use npm or yarn to install them.
   - Require them here, like `require('bootstrap');`
   - Leave the `$ = window.$` before the requires.
*/
$ = window.$;

var oficiales = [
  {
    name: 'Jorge Garzón',
    imageUrl: 'https://itau.com.uy/imagen.jpg',
    accountType: 'Personas',
    tel: 26223344,
    int: 2909,
  },
  {
    name: 'Martín Molinari',
    imageUrl: 'https://itau.com.uy/imagen.jpg',
    accountType: 'PYMES',
    tel: 26223344,
    int: 2910,
  },
  {
    name: 'Mauricio Lapetina',
    imageUrl: 'https://itau.com.uy/imagen.jpg',
    accountType: 'Personal Bank',
    tel: 26223344,
    int: 2966,
  },
];

if (Array.isArray(oficiales) && oficiales.length === 1) {
  // strictly only one element
  console.log('tienes un solo oficial');
  $('#chat-container').addClass('one-oficial');
} else {
  // not only one element
  console.log('tienes varios oficiales');
  $('#chat-container').removeClass('one-oficial');
}

$('#btn-open-chat').on('click touch', function() {
  $(this).toggleClass('opened-chat');
  OpenChatDigital();
});

$('.btn-close-chat-mobile').on('click touch', function() {
  $('#btn-open-chat').toggleClass('opened-chat');
  OpenChatDigital();
});

function OpenChatDigital() {
  if ($('#btn-open-chat').hasClass('opened-chat')) {
    $('#btn-open-chat .btn-cont-static')
      .delay(205)
      .hide();

    $('#btn-open-chat .icon-close').css({
      transform: 'rotate(0deg)',
    });

    $('#chat-container').css({
      opacity: '1',
      transform: 'translateY(0)',
      'z-index': '3232322',
      transition: 'all 0.4s cubic-bezier(.475,.425,0,.995)',
    });
  } else {
    $('#btn-open-chat .btn-cont-static').show(200);

    $('#btn-open-chat .icon-close').css({
      transform: 'rotate(-45deg)',
    });

    $('#chat-container').css({
      opacity: '0',
      transform: 'translateY(20px)',
      'z-index': '-22',
      transition: 'all 0.4s cubic-bezier(.475,.425,0,.995)',
    });
  }
}

$('.btn-back-chats').on('click touch', function() {
  $('#chat-container >.col-12').css({
    transform: 'translateX(0)',
    transition: 'transform 0.35s ease-out',
  });
});

$('#chat-container #content-oficiales .oficiales-list').on('click touch', function() {
  $('#chat-container >.col-12').css({
    transform: 'translateX(-100%)',
    transition: 'transform 0.35s ease-out',
  });
});
